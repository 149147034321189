import {Injectable} from '@angular/core';

import {Order, QueryConfig} from '@datorama/akita';
import {BaseQuery} from "../../datasource/redux/base.query";
import {ReinsuranceRateStoreService} from './reinsurance-rate-store.service';
import {ReinsuranceRateState} from "./reinsurance-rate.state";

@Injectable({
	providedIn: 'root'
})
@QueryConfig({
	sortBy: 'version',
	sortByOrder: Order.DESC // Order.DESC
})
export class ReinsuranceRateQueryService extends BaseQuery<ReinsuranceRateState> {

	constructor(protected store: ReinsuranceRateStoreService) {
		super(store);
	}
}
