import {Injectable} from '@angular/core';
import {DATASOURCE_NAMES} from "../../datasource/datasource.url.config";
import {DatasourceService} from "../../datasource/domain/datasource.service";
import {BaseService} from "../../datasource/redux/base.service";
import {ReinsuranceRateQueryService} from "./reinsurance-rate-query.service";
import {ReinsuranceRateStoreService} from "./reinsurance-rate-store.service";
import {ReinsuranceRateModel} from "./reinsurance-rate.model";
import {ReinsuranceRateState} from "./reinsurance-rate.state";

const model = DATASOURCE_NAMES.MODEL_REINSURANCE_RATE;

@Injectable({
	providedIn: 'root',
})
export class ReinsuranceRateService extends BaseService<ReinsuranceRateState, ReinsuranceRateModel> {

	constructor(private reinsuranceRateStoreService: ReinsuranceRateStoreService,
							private reinsuranceRateQueryService: ReinsuranceRateQueryService,
							public datasource: DatasourceService) {
		super(reinsuranceRateStoreService, reinsuranceRateQueryService, datasource);
	}

	model(): string {
		return model;
	}
}
