import {Injectable} from '@angular/core';
import {StoreConfig} from '@datorama/akita';
import {BaseStore, createInitialState} from "../../datasource/redux/base.store";
import {ReinsuranceRateState} from "./reinsurance-rate.state";


const initialState = createInitialState({});

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'reinsurance-rate', resettable: true, idKey: 'version'})
export class ReinsuranceRateStoreService extends BaseStore<ReinsuranceRateState> {
	constructor() {
		super(initialState);
	}

}

